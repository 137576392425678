/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const React = require('react')
const { default: CookieBanner } = require('./src/components/cookieBanner')
const { DarkModeProvider } = require('./src/context/DarkModeContext')

exports.wrapRootElement = ({ element }) => (
  <DarkModeProvider>
    {element}
    <CookieBanner />
  </DarkModeProvider>
)

exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
    `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

// * Prevent scroll restoration
// ? https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const gatsbyWrapper = document.getElementById('gatsby-focus-wrapper')
  const shouldPreventSmoothScrolling = location.pathname.match(/\/(lazy-hacks|blog|project)\/.+/)
  if (shouldPreventSmoothScrolling) {
    // * prevent smooth scrolling when navigating to articles or projects
    gatsbyWrapper.style.scrollBehavior = 'initial'
  }

  if (!location.hash) {
    // ! Location is not saved and in most cases `currentPosition` = [0, 0]
    // * Scroll to top only when no hashes are in place - no anchor has been clicked
    const currentPosition = getSavedScrollPosition(location)
    gatsbyWrapper.scrollTo(...(currentPosition || [0, 0]))
  }

  if (shouldPreventSmoothScrolling) {
    // * enable smooth scrolling back
    gatsbyWrapper.style.scrollBehavior = 'smooth'
  }
  return false
}

exports.onRouteUpdate = ({ location }) => {
  if (typeof window.Prism === "object") {
    window.Prism.highlightAll()
  }
}