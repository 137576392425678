import { OutboundLink as GtagOutboundLink } from 'gatsby-plugin-gtag'
import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'

import { outboundLinkIcon } from './../typography.module.css'

const OutboundLink = ({ mark, href, children, target, noIcon, markKey, ...props }) => {
  // * Remove `markKey` from the rest of the props - throws a React error when adding to DOM
  if (mark) {
    href = mark.href
    target = mark.blank ? '_blank' : '_self'
  }

  return (
    <GtagOutboundLink
      {...props}
      href={href}
      target={target}
    >
      {children}
      {(target === '_blank' && !noIcon) && <FaExternalLinkAlt className={outboundLinkIcon} />}
    </GtagOutboundLink>
  )
}

export default OutboundLink
