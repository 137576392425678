import React, { createContext, useEffect, useState } from 'react'

export const DarkModeContext = createContext()

const DARK_MODE_NAME = 'DARK_MODE'

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(true)

  useEffect(() => {
    // ! Initial flash of light theme when on dark mode
    initialiseMode()
  })

  const saveMode = mode => {
    if (typeof window === 'undefined') {
      return
    }
    window.localStorage.setItem(DARK_MODE_NAME, mode)
  }

  const initialiseMode = () => {
    const savedMode = window.localStorage.getItem(DARK_MODE_NAME)
    if (savedMode === null) {
      // ! if user changed the preferred mode it won't be detected
      const darkModeOn = window.matchMedia('screen and (prefers-color-scheme: dark)').matches
      setDarkMode(darkModeOn)
      saveMode(darkModeOn)
    } else {
      setDarkMode(savedMode === 'true')
    }
  }

  const toggleMode = () => {
    setDarkMode(!darkMode)
    saveMode(!darkMode)
  }

  return (
    <DarkModeContext.Provider value={{ darkMode, toggleMode }}>
      {children}
    </DarkModeContext.Provider>
  )
}
